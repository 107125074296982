<template>
  <calc-result-page>
    <div class="res-box">
      <div>在职人员死亡待遇：</div>
      <div>
        <span class="res">{{res.total}}</span>
        <span class="unit">元</span>
      </div>
    </div>

    <fee-consititute-container>
      <res-list-item title="丧葬费" :content="res.funeral + '元'"></res-list-item>
      <res-list-item title="一次性补助金" :content="res.subsidy + '元'"></res-list-item>
      <res-list-item title="个人账户累计储蓄额本息" :content="res.balance + '元'"></res-list-item>
    </fee-consititute-container>

    <div class="tip">以上结果只是测算，最终以社保局计算结果为准</div>
  </calc-result-page>
</template>

<script>
import CalcResultPage from "@/components/CalcResultPage";
import ResListItem from "@/views/calc/components/ResListItem";
import FeeConsitituteContainer from '@/views/calc/components/FeeConsitituteContainer.vue';

export default {
  components: {
    ResListItem,
    CalcResultPage,
    FeeConsitituteContainer 
  },
  mounted() {
   const res = this.$route.query.res
    if (res !== undefined) {
      this.res = JSON.parse(this.$route.query.res);
    }
  },
  data() {
    return {
      res: {},
    };
  },
};
</script>

<style scoped>
.res-box {
  margin-left: 20px;
  color: #4d4d4d;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 50px;
}

.res {
  font-size: 44px;
  color: #658CF1;
}

.unit {
  font-size: 16px;
  color: #999999;
}

.tip {
  margin-top: 120px;
  margin-left: 40px;
  font-size: 12px;
  color: #658CF1;
}
</style>